
import { computed, defineComponent, reactive, ref, toRefs } from 'vue'
import moment from 'moment'
import { useMeta } from 'vue-meta'
import { useField, useResetForm, useForm } from 'vee-validate'
import { Accidents } from '@/models/interfaces/modal-steps/step10/Step-10-accident'
import { axiosApi } from '@/models/http/axiosApi'
import getYearsLater from '@/helpers/getYearsLater'
import ScrollToMixin from '@/mixins/scrollToDiv'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-11-accidents',
  props: [
    'currentStep'
  ],
  mixins: [ScrollToMixin],
  setup (props, { emit }) {
    useMeta({
      title: 'Accidents'
    })

    const { currentStep } = toRefs(props)
    const { errors } = useForm<Accidents>()
    const { value: month } = useField<string>('month')
    const { value: year } = useField<number>('year')
    const { value: description } = useField<number>('description')
    const { value: fault } = useField<boolean | undefined>('fault')
    const { value: damage } = useField<number>('damage')
    const dataArr: Array<Accidents> = reactive([])
    const limit = 4
    const resetForm = useResetForm()

    const checkFields = computed<string>(
      () => {
        const classes = ref('')
        classes.value = month.value && year.value && description.value && fault.value !== undefined && damage.value
          ? 'cursor-pointer'
          : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )

    const formatData = () => {
      return moment(`${year.value}-${moment().month(month.value).format('MM')}-01`).format('YYYY-MM-DD')
    }

    const addAnother = () => {
      if (month.value && year.value && description.value && fault.value !== undefined && damage.value) {
        dataArr.push({
          date: formatData(),
          id: description.value,
          fault: fault.value,
          damageId: damage.value
        })
        resetForm()
      }
    }

    const deleteQuote = (index: number) => {
      dataArr.splice(index, 1)
    }

    const passData = () => {
      if (month.value && year.value && description.value && fault.value !== undefined && damage.value) {
        dataArr.push({
          date: formatData(),
          id: description.value,
          fault: fault.value,
          damageId: damage.value
        })
        segmentTracking('Clicked On Continue Accidents', currentStep.value)
        resetForm()
        return emit('nextStep', { toggle: false, step: 'accident', accidents: dataArr })
      }
      return null
    }

    return {
      errors,
      month,
      year,
      description,
      fault,
      damage,
      passData,
      dataArr,
      addAnother,
      deleteQuote,
      limit,
      checkFields
    }
  },
  data () {
    return {
      months: {},
      years: {},
      answers: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      accidents: {},
      damages: {}
    }
  },
  mounted () {
    this.years = getYearsLater(3)
    this.months = moment.months()
    this.getAccidents()
    this.getDamages()
  },
  methods: {
    toTop () {
      this.scrollToDivId('card_panel modal-top', 100)
    },
    getAccidents () {
      axiosApi.get('/incident/accidents')
        .then((response) => {
          this.accidents = response.data
        })
    },
    getDamages () {
      axiosApi.get('/incident/damages')
        .then((response) => {
          this.damages = response.data
        })
    }
  }
})
