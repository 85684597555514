
import { defineComponent } from 'vue'
import _ from 'lodash'
import moment from 'moment'
import { Step9, Birthday } from '@/models/interfaces/modal-steps/Step-9'
import { notify } from '@kyvg/vue3-notification'
import { segmentTracking } from '@/helpers/clickEventTracking'
import ScrollToMixin from '@/mixins/scrollToDiv'

export default defineComponent({
  name: 'Step-9',
  mixins: [ScrollToMixin],
  data (): Step9 {
    return {
      months: moment.months(),
      days: [],
      years: [],
      monthStep: true,
      dayStep: false,
      yearStep: false,
      currentStep: 9,
      nextStep: 10,
      bMonth: 0,
      bDay: 0,
      bYear: 0,
      birthday: '',
      yearsToShow: 20,
      step: 20
    }
  },
  mounted (this: Birthday): void {
    const yearsArr = new Date().getFullYear()
    this.years = _.sortBy(Array.from({ length: 100 }, (v, i) => yearsArr - 100 + i + 1), function (num: number) {
      return num * -1
    })
    this.years = this.years.slice(18, 100)
  },
  beforeCreate (this: Birthday): void {
    const yearsArr = new Date().getFullYear()
    this.years = Array.from({ length: 100 }, (v, i) => yearsArr - 100 + i + 1)
    this.years = _.sortBy(this.years, function (num: number) {
      return num
    })
  },
  computed: {
    visibleYears (this: Birthday): unknown {
      return this.years.slice(0, this.yearsToShow)
    }
  },
  methods: {
    toTop () {
      this.scrollToDivId('card_panel modal-top', 100)
    },
    passData (this: Birthday, val: number): void {
      if (this.monthStep) {
        this.bMonth = val
        const month = moment().month(this.bMonth)
        this.days = Array.from(Array(moment(month).daysInMonth()), (_, i) => i + 1)
        // add day #29 to February to array in case of a Leap Year
        if (this.days.length === 28) {
          this.days.push(29)
        }
        this.monthStep = false
        this.dayStep = true
        segmentTracking('Clicked On Birthday Month', this.currentStep)
      } else if (this.dayStep) {
        this.bDay = val
        this.dayStep = false
        segmentTracking('Clicked On Birthday Day', this.currentStep)
      } else {
        this.bYear = val
        const startDate = moment(new Date(), 'MM-DD-YYYY')
        const convertedMonthInt = moment().month(this.bMonth).format('M')
        const endDate = moment(convertedMonthInt + '-' + this.bDay + '-' + this.bYear, 'MM-DD-YYYY')
        const checkYear = moment(startDate).diff(endDate, 'years', true)
        const date = moment(this.bYear + '-' + (this.bMonth + 1) + '-' + this.bDay, 'YYYY-MM-DD')
        segmentTracking('Clicked On Birthday Year', this.currentStep)
        if (endDate.isValid()) {
          if (checkYear >= 18) {
            this.$store.dispatch('setBirthday', {
              birthday: moment(date).format('YYYY-MM-DD'),
              currentStep: this.nextStep,
              pBar: 10
            })
          } else {
            notify({
              group: 'validate',
              title: 'Minimum Age Requirement',
              text: 'Age requirement set to 18',
              type: 'error'
            })
          }
        } else {
          notify({
            group: 'validate',
            title: 'Valid date is required',
            text: 'Check if date is valid',
            type: 'error'
          })
        }
      }
    }
  }
})
