
import { computed, defineComponent, ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import _ from 'lodash'
import * as yup from 'yup'
import { Step15Data, Validation } from '@/models/interfaces/modal-steps/Step-15'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-15',
  data (): Step15Data {
    return {
      currentStep: 15,
      nextStep: 16
    }
  },
  setup () {
    const schema = yup.object({
      email: yup.string().email('Must be a valid email').max(255).required('Email is required')
    })
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: email } = useField('email')

    const checkFields = computed(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && email.value ? 'cursor-pointer' : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )

    return {
      errors,
      checkFields,
      email
    }
  },
  methods: {
    passData () {
      if (_.size(this.errors.email) === 0 && this.email) {
        this.$store.dispatch('setEmail', {
          email: this.email,
          currentStep: this.nextStep,
          pBar: 16
        })
        segmentTracking('Clicked On Continue', this.currentStep)
      }
    }
  }
})
