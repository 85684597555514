
import { defineComponent, onMounted, watch } from 'vue'
import Layout from '@/views/layouts/Layout.vue'
import { useMeta } from 'vue-meta'
import { Data } from '@/models/interfaces/App'
import lidStatic from '@/models/external/lidStatic'
import { segmentScript } from '@/models/external/addSegmentScript.ts'
import { trustedFormScript } from '@/models/external/trustedForm.ts'
import { useRoute } from 'vue-router'
import { VueCookieNext } from 'vue-cookie-next'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'App',
  data: (): Data => {
    return {
      siteTitle: useStore().state.siteSettings.title ? useStore().state.siteSettings.title : ''
    }
  },
  setup () {
    const route = useRoute()
    const analyticsProps = {
      campaignRequestId: VueCookieNext.getCookie('campaign_request_id') ?? 'none'
    }
    onMounted(() => {
      console.log(`App version - ${process.env.VUE_APP_VERSION}`)
      segmentScript(route.name)
      trustedFormScript()
    })
    watch(() => route.name, () => {
      window.analytics.page(route.name, analyticsProps)
    })

    useMeta({
      title: '',
      script: [
        { ...lidStatic }
      ],
      link: [
        {
          rel: 'icon',
          href: useStore().state.siteSettings.favicon ? useStore().state.siteSettings.favicon.url : ''
        }
      ]
    })
  },
  components: {
    Layout
  }
})
