const getYearsLater = (yearsLater: number): Array<number> => {
  const endYear = new Date().getFullYear()
  const years = []
  for (let i = endYear; i >= (endYear - yearsLater); i--) {
    years.push(i)
  }

  return years
}

export default getYearsLater
