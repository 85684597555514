import Client from '@/models/interfaces/Client'

export default class ClientDto {
  public readonly agreementTerms: boolean
  public readonly agreementCollectData: boolean
  public readonly phone: string
  public readonly leadId: string
  public readonly trustedFormCertUrl: string
  public readonly trustedFormPingUrl: string
  public readonly zipcode: string
  public readonly street: string
  public readonly state: string
  public readonly city: string
  public readonly ownership: string
  public readonly email: string
  public readonly agreementText: string
  public readonly campaignRequestId: string

  constructor (data: Client) {
    this.agreementTerms = data.agreementTerms ? Boolean(data.agreementTerms) : false
    this.agreementCollectData = data.agreementCollectData ? Boolean(data.agreementCollectData) : false
    this.phone = data.phone ? String(data.phone) : '0'
    this.leadId = data.leadId ? String(data.leadId) : ''
    this.trustedFormCertUrl = data.trustedFormCertUrl ? String(data.trustedFormCertUrl) : ''
    this.trustedFormPingUrl = data.trustedFormPingUrl ? String(data.trustedFormPingUrl) : ''
    this.zipcode = data.zipcode ? String(data.zipcode) : ''
    this.street = data.street ? String(data.street) : ''
    this.state = data.state ? String(data.state) : ''
    this.city = data.city ? String(data.city) : ''
    this.ownership = data.ownership ? String(data.ownership) : ''
    this.email = data.email ? String(data.email) : ''
    this.agreementText = data.agreementText ? String(data.agreementText) : ''
    this.campaignRequestId = data.campaignRequestId ? String(data.campaignRequestId) : ''
  }
}
