import router from '@/router'
import { VueCookieNext } from 'vue-cookie-next'
import { Segment } from '@/models/interfaces/tracking/segment'

export function segmentTracking (text: string, step = 0, data?: Record<string, unknown>): void {
  const analyticsProps: Segment = {
    page: router.currentRoute.value.name,
    path: router.currentRoute.value.path,
    campaignRequestId: VueCookieNext.getCookie('campaign_request_id') ?? 'none',
    ...data
  }

  let textTrack = ''
  if (step > 0) {
    analyticsProps.step = step
    textTrack = `${text} - Step-${step}`
  } else {
    textTrack = `${text} - ${String(router.currentRoute.value.name)}`
  }

  window.analytics.track(textTrack, analyticsProps)
}
