import { addScript } from '@/models/external/scripts'

export function trustedFormScript (): void {
  addScript('head', `
  (function() {
  var tf = document.createElement('script');
  tf.type = 'text/javascript'; tf.async = true;
  tf.src = ("https:" == document.location.protocol ? 'https' : 'http') + "://api.trustedform.com/trustedform.js?field=TrustedFormCertUrl&ping_field=TrustedFormPingUrl&l=" + new Date().getTime() + Math.random();
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
  })();`)

  addScript('head', `
  <img src="https://api.trustedform.com/ns.gif" />
  `, true)
}
