

import { defineComponent, ref } from 'vue'
import Preloader from '@/views/components/Preloader.vue'
import Step1 from '@/views/components/modal-steps/Step-1.vue'
import Step2 from '@/views/components/modal-steps/Step-2.vue'
import Step3 from '@/views/components/modal-steps/Step-3.vue'
import Step4 from '@/views/components/modal-steps/Step-4.vue'
import Step5 from '@/views/components/modal-steps/Step-5.vue'
import Step6 from '@/views/components/modal-steps/Step-6.vue'
import Step7 from '@/views/components/modal-steps/Step-7.vue'
import Step8 from '@/views/components/modal-steps/Step-8.vue'
import Step9 from '@/views/components/modal-steps/Step-9.vue'
import Step10 from '@/views/components/modal-steps/Step-10.vue'
import Step11 from '@/views/components/modal-steps/Step-11.vue'
import Step12 from '@/views/components/modal-steps/Step-12.vue'
import Step13 from '@/views/components/modal-steps/Step-13.vue'
import Step14 from '@/views/components/modal-steps/Step-14.vue'
import Step15 from '@/views/components/modal-steps/Step-15.vue'
import Step16 from '@/views/components/modal-steps/Step-16.vue'

import { segmentTracking } from '@/helpers/clickEventTracking'
import { VueCookieNext } from 'vue-cookie-next'
import Footer from '@/views/layouts/parts/Footer.vue'
import Header from '@/views/layouts/parts/Header.vue'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const leadToken = ref(null)
    return {
      leadToken
    }
  },
  name: 'ModalSteps',
  mixins: [ScrollToMixin],
  mounted () {
    this.$store.state.isModalVisible = useStore().state.isModalVisible
    useStore().commit('setOpen', false)
  },
  watch: {
    '$store.state.currentStep' (val) {
      if (val > 0) {
        const analyticsProps = {
          campaignRequestId: VueCookieNext.getCookie('campaign_request_id') ?? 'none'
        }
        window.analytics.page(`Form Step #${this.$store.state.currentStep}`, analyticsProps)
        this.toTop()
      }
    },
    '$store.state.isModalVisible' (val) {
      document.body.className = val ? 'overflow-hidden' : 'overflow-visible'
    },
    '$route' (val) {
      val && (document.body.className = 'overflow-visible')
    }
  },
  computed: {
    isDisabled () {
      return (this.$store.state.currentStep === 5 || this.$store.state.currentStep === 12)
    }
  },
  methods: {
    closeModal () {
      document.body.className = 'overflow-visible'
      this.$store.commit('setOpen', false)
      segmentTracking('Clicked On Close Form Button')
    },
    nextStep () {
      this.$store.state.currentStep = this.$store.commit('setStep', this.$store.state.currentStep + 1)
    },
    toTop () {
      this.scrollToDivId('card_panel modal-top', 100)
    },
    prevStep () {
      if (this.$store.state.currentStep < 2) {
        this.closeModal()
        segmentTracking('Clicked On Back Button - Close Form')
      } else {
        this.$store.commit('setProgressBar', this.$store.state.pBar - 1)
        this.$store.commit('setStep', this.$store.state.currentStep - 1)
        segmentTracking('Clicked On Back Button')
      }
    },
    pBarCalc (value: number) {
      return Math.round(value * 100 / 17)
    }
  },
  components: {
    Header,
    Footer,
    Step16,
    Step15,
    Step14,
    Step13,
    Step12,
    Step11,
    Step10,
    Step9,
    Step8,
    Step7,
    Step6,
    Step5,
    Step4,
    Step3,
    Step2,
    Step1,
    Preloader
  }
})
