import Car from '@/models/interfaces/Car'

export default class CarDto {
  public readonly ownership: boolean
  public readonly year: number
  public readonly maker: string
  public readonly model: string

  constructor (data: Car) {
    this.ownership = data.ownership ? Boolean(data.ownership) : false
    this.year = data.year ? Number(data.year) : 0
    this.maker = data.maker ? String(data.maker) : ''
    this.model = data.model ? String(data.model) : ''
  }
}
