
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import ModalSteps from '@/views/components/modal-steps/ModalSteps.vue'
import Zipcode from '@/views/components/modal-steps/Zipcode.vue'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'

export default defineComponent({
  name: 'Home',
  mixins: [ScrollToMixin],
  setup () {
    useMeta({
      title: 'Drop Your Insurance Rates Today'
    })
  },
  components: {
    Zipcode,
    ModalSteps
  }
})
