import { createStore } from 'vuex'
import { Data } from '@/models/interfaces/modal-steps/ModalSteps'

function initialState () {
  return {
    openForm: false,
    client: {
      zipcode: '',
      street: '',
      state: '',
      city: '',
      ownership: '',
      email: '',
      phone: 0,
      agreementTerms: false,
      agreementCollectData: false,
      agreementText: '',
      leadId: '',
      trustedFormCertUrl: '',
      trustedFormPingUrl: '',
      campaignRequestId: ''
    },
    preCars: [],
    preCar: {
      vehicleYear: 0,
      vehicleMaker: '',
      vehicleModel: '',
      vehicleOwnership: false
    },
    preDrivers: [],
    preDriver: {
      currentlyInsured: false,
      insuranceCompanyId: 0,
      gender: '',
      married: false,
      birthday: '',
      accidents: [],
      tickets: [],
      duis: [],
      firstName: '',
      lastName: '',
      ownership: false
    },
    pBar: 1,
    isModalVisible: false,
    currentStep: 0,
    carsCounter: 0,
    driversCounter: 0,
    quote: [],
    quoteDrivers: [],
    birthMonth: 0,
    birthDay: 0,
    birthYear: 0,
    spinner: false,
    siteSettings: {
      domain: '',
      logo: '',
      title: ''
    }
  }
}

export default createStore({
  state: (): Data => initialState(),

  mutations: {
    setOpen (state, value) {
      state.isModalVisible = value
    },
    setStep (state, value) {
      state.currentStep = value
    },
    setProgressBar (state, value) {
      state.pBar = value
    },
    setYear (state, value) {
      state.preCar.vehicleYear = value
    },
    setMaker (state, value) {
      state.preCar.vehicleMaker = value
    },
    setModel (state, value) {
      state.preCar.vehicleModel = value
    },
    setCarOwner (state, value) {
      state.preCar.vehicleOwnership = value
    },
    setCurrentlyInsured (state, value) {
      state.preDriver.currentlyInsured = value
    },
    setInsuranceCompanyId (state, value) {
      state.preDriver.insuranceCompanyId = value
    },
    setGender (state, value) {
      state.preDriver.gender = value
    },
    setMarriageStatus (state, value) {
      state.preDriver.married = value
    },
    setBirthday (state, value) {
      state.preDriver.birthday = value
    },
    addDriver (state) {
      state.preDrivers.push({
        currentlyInsured: state.preDriver.currentlyInsured,
        insuranceCompanyId: state.preDriver.insuranceCompanyId,
        gender: state.preDriver.gender,
        married: state.preDriver.married,
        birthday: state.preDriver.birthday,
        accidents: state.preDriver.accidents,
        tickets: state.preDriver.tickets,
        duis: state.preDriver.duis,
        firstName: state.preDriver.firstName,
        lastName: state.preDriver.lastName,
        ownership: state.preDriver.ownership
      })
    },
    setSiteSettings (state, value) {
      state.siteSettings = value
    },
    resetState: function (state: Record<string, any>) {
      const s: Record<string, unknown> = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    setZipCode ({ commit, state }, data) {
      commit('setOpen', true)
      commit('setProgressBar', data.pBar)
      commit('setStep', data.currentStep)
      state.client.zipcode = data.zipcode
      state.client.city = data.city
      state.client.state = data.state
    },
    setVYearData ({ commit }, data) {
      commit('setProgressBar', data.pBar)
      commit('setStep', data.currentStep)
      commit('setYear', data.year)
    },
    setVMakerData ({ commit }, data) {
      commit('setProgressBar', data.pBar)
      commit('setStep', data.currentStep)
      commit('setMaker', data.maker)
    },
    setVModelData ({ commit }, data) {
      commit('setProgressBar', data.pBar)
      commit('setStep', data.currentStep)
      commit('setModel', data.model)
    },
    setVOwnershipData ({ state, commit }, data) {
      commit('setProgressBar', data.pBar)
      commit('setStep', data.currentStep)
      commit('setCarOwner', data.ownership)
      state.preCars.push(
        {
          year: state.preCar.vehicleYear,
          maker: state.preCar.vehicleMaker,
          model: state.preCar.vehicleModel,
          ownership: state.preCar.vehicleOwnership
        }
      )
    },
    deleteVehicle ({ state, commit }) {
      state.preCars = []
      state.carsCounter = 0
      commit('setProgressBar', 1)
    },
    setVehicle ({ state, commit }, data) {
      if (data.answer === 'yes') {
        state.carsCounter = data.carsCounter
      } else {
        commit('setStep', state.currentStep + 1)
        commit('setProgressBar', data.pBar)
        state.quote = state.preCars
      }
    },
    setCurrentlyInsured ({ commit }, data) {
      commit('setCurrentlyInsured', data.answer)
      commit('setInsuranceCompanyId', data.insuranceCompanyId)
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setGender ({ commit }, data) {
      commit('setGender', data.gender)
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setMarriage ({ commit }, data) {
      commit('setMarriageStatus', data.married)
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setBirthday ({ commit }, data) {
      commit('setBirthday', data.birthday)
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    getDInfo ({ state, commit }, data) {
      state.preDriver.accidents = data.accidents ? data.accidents : []
      state.preDriver.tickets = data.tickets ? data.tickets : []
      state.preDriver.duis = data.duis ? data.duis : []
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setFullName ({ state, commit }, data) {
      state.preDriver.firstName = data.firstName
      state.preDriver.lastName = data.lastName
      commit('addDriver')
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    deleteDriver ({ state, commit }) {
      state.preDrivers = []
      state.driversCounter = 0
      commit('setProgressBar', 6)
    },
    setDriver ({ state, commit }, data) {
      if (data.answer === 'yes') {
        state.driversCounter = data.driversCounter
      } else {
        commit('setStep', state.currentStep + 1)
        commit('setProgressBar', data.pBar)
        state.quoteDrivers = state.preDrivers
      }
    },
    setAddress ({ state, commit }, data) {
      state.client.street = data.street
      state.client.zipcode = data.zipcode
      state.client.state = data.state
      state.client.city = data.city
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setPropertyOwnership ({ state, commit }, data) {
      state.client.ownership = data.answer
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setEmail ({ state, commit }, data) {
      state.client.email = data.email
      commit('setStep', data.currentStep)
      commit('setProgressBar', data.pBar)
    },
    setPhoneData ({ state }, data) {
      state.spinner = true
      state.client.phone = data.phone
      state.client.agreementTerms = data.agreementTerms
      state.client.agreementCollectData = data.agreementCollectData
      state.client.leadId = data.leadId
      state.client.trustedFormCertUrl = data.trustedFormCertUrl
      state.client.trustedFormPingUrl = data.trustedFormPingUrl
      state.client.agreementText = data.agreementText
      state.client.campaignRequestId = data.cookie
    },
    setSiteData ({ commit }, data) {
      commit('setSiteSettings', data)
    }
  }
})
