
import { defineComponent } from 'vue'
import { Data } from '@/models/interfaces/Footer'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Footer',
  mixins: [ScrollToMixin],
  data: (): Data => {
    return {
      siteDomain: useStore().state.siteSettings.domain ? useStore().state.siteSettings.domain : '',
      logo: useStore().state.siteSettings.logo ? useStore().state.siteSettings.logo.url : ''
    }
  }
})
