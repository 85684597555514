
import { defineComponent } from 'vue'
import { Step2 } from '@/models/interfaces/modal-steps/Step-2'
import Preloader from '@/views/components/Preloader.vue'
import { axiosApi } from '@/models/http/axiosApi'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-2',
  components: { Preloader },
  data (): Step2 {
    return {
      makers: [],
      makersToShow: 20,
      step: 20,
      currentStep: 2,
      nextStep: 3,
      spinner: true,
      year: this.$store.state.preCar.vehicleYear
    }
  },
  mounted () {
    axiosApi.get(`/car/makers/${this.year}`)
      .then((response) => {
        this.makers = response.data
        this.spinner = false
      })
  },
  computed: {
    visibleMakers (this: Step2) {
      return this.makers.slice(0, this.makersToShow)
    }
  },
  methods: {
    passData (val: string) {
      this.$store.dispatch('setVMakerData', {
        currentStep: this.nextStep,
        pBar: 3,
        maker: val
      })
      segmentTracking('Clicked On Car Make', this.currentStep)
    },
    carIconTag (val: string) {
      return `car-${val.toLocaleLowerCase().replace(/ /g, '-')}`
    }
  }
})
