
import { defineComponent } from 'vue'
import { Step3 } from '@/models/interfaces/modal-steps/Step-3'
import Preloader from '@/views/components/Preloader.vue'
import { axiosApi } from '@/models/http/axiosApi'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-3',
  components: { Preloader },
  data (): Step3 {
    return {
      models: [],
      modelsToShow: 9,
      step: 9,
      currentStep: 3,
      nextStep: 4,
      spinner: true,
      year: this.$store.state.preCar.vehicleYear,
      maker: this.$store.state.preCar.vehicleMaker
    }
  },
  mounted () {
    axiosApi.get(`/car/models/${this.year}/${this.maker}`)
      .then((response) => {
        this.models = response.data
        this.spinner = false
      })
  },
  computed: {
    visibleModels (this: Step3) {
      return this.models.slice(0, this.modelsToShow)
    }
  },
  methods: {
    passData (val: string) {
      this.$store.dispatch('setVModelData', {
        currentStep: this.nextStep,
        pBar: 4,
        model: val
      })
      segmentTracking('Clicked On Vehicle Model', this.currentStep)
    }
  }
})
