import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import Notifications from '@kyvg/vue3-notification'
import Cookie from './models/external/cookie'

const app = createApp(App)
  .use(router)
  .use(store)
  .use(createMetaManager())
  .use(Notifications)
  .use(Cookie)

router.isReady().then(() => {
  app.mount('#app')
})
