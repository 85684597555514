
import { computed, defineComponent, ref, onMounted } from 'vue'
import { Step13Data, Validation } from '@/models/interfaces/modal-steps/Step-13'
import { useForm, useField } from 'vee-validate'
import _ from 'lodash'
import * as yup from 'yup'
import StatesMap from '@/map/states.map'
import { segmentTracking } from '@/helpers/clickEventTracking'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Step-13',
  data (): Step13Data {
    return {
      statesDropDown: StatesMap,
      currentStep: 13,
      nextStep: 14
    }
  },
  setup () {
    const zipcode = useStore().state.client.zipcode
    const city = useStore().state.client.city
    const state = useStore().state.client.state

    onMounted(() => {
      if (zipcode) {
        zipcodeChange.value = zipcode
      }
      if (city) {
        cityChange.value = city
      }
      if (state) {
        stateChange.value = state
      }
    })

    const schema = yup.object({
      street: yup.string()
        .required()
        .label('Street')
        .matches(/\d+\s+\w+/gi, 'Only alphabets and numbers are allowed for this field ')
        .nullable(true)
        .min(2),
      stateChange: yup.string()
        .required()
        .nullable(true)
        .max(2),
      zipcodeChange: yup.string()
        .required('Zipcode is a required field')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits'),
      cityChange: yup.string()
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
    })

    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: street } = useField('street')
    const { value: stateChange } = useField('stateChange')
    const { value: zipcodeChange } = useField('zipcodeChange')
    const { value: cityChange } = useField('cityChange')

    const checkFields = computed(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && street.value && stateChange.value && zipcodeChange.value && cityChange.value
          ? 'cursor-pointer'
          : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )

    return {
      errors,
      zipcode,
      city,
      state,
      street,
      stateChange,
      zipcodeChange,
      cityChange,
      checkFields
    }
  },
  methods: {
    passData () {
      if (((_.size(this.errors)) === 0 && this.street && this.stateChange && this.zipcodeChange && this.cityChange)) {
        this.$store.dispatch('setAddress', {
          street: this.street,
          zipcode: this.zipcodeChange,
          state: this.stateChange,
          city: this.cityChange,
          currentStep: this.nextStep,
          pBar: 14
        })
        segmentTracking('Clicked On Continue', this.currentStep)
      }
    }
  }
})
