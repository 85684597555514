
import { defineComponent } from 'vue'
import { Step5 } from '@/models/interfaces/modal-steps/Step-5'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-5',
  data (): Step5 {
    return {
      answers: [
        { answer: 'yes' },
        { answer: 'no' }
      ],
      cars: this.$store.state.preCars,
      currentStep: 5,
      nextStep: 6,
      updatedCarsCounter: this.$store.state.preCars.length,
      limit: 5
    }
  },
  methods: {
    passData (val: string) {
      if (val === 'yes') {
        this.$store.commit('setProgressBar', 1)
        this.$store.commit('setStep', 1)
        segmentTracking('Clicked On Add Additional Vehicle', this.currentStep)
      } else {
        this.$store.dispatch('setVehicle', {
          pBar: 6,
          nextStep: this.nextStep,
          carsCounter: this.updatedCarsCounter + 1,
          answer: val
        })
        segmentTracking('Clicked On Add Additional Vehicle', this.currentStep)
      }
    },
    deleteQuote (index: number) {
      if (index === 0) {
        this.$store.dispatch('deleteVehicle')
        this.$store.commit('setStep', 1)
      } else {
        this.updatedCarsCounter--
        this.cars.splice(index, 1)
      }
    }
  }
})
