import ClientDto from '@/models/dto/ClientDto'
import CarDto from '@/models/dto/CarDto'
import DriverDto from '@/models/dto/DriverDto'
import Client from '@/models/interfaces/Client'
import Car from '@/models/interfaces/Car'
import Driver from '@/models/interfaces/Driver'

export default class CreateClientDto {
  public readonly client: ClientDto
  public readonly cars: Array<CarDto>
  public readonly drivers: Array<DriverDto>

  constructor (client: Client, cars: Array<Car>, drivers: Array<Driver>) {
    this.client = new ClientDto(client)
    this.cars = cars ? cars.map((item: Car) => new CarDto(item)) : []
    this.drivers = drivers ? drivers.map((item: Driver) => new DriverDto(item)) : []
  }
}
