
import { defineComponent } from 'vue'
import { Step6 } from '@/models/interfaces/modal-steps/Step-6'
import { segmentTracking } from '@/helpers/clickEventTracking'
import ScrollToMixin from '@/mixins/scrollToDiv'

export default defineComponent({
  name: 'Step-6',
  mixins: [ScrollToMixin],
  data (): Step6 {
    return {
      answers: [
        { answer: 'yes', value: true },
        { answer: 'no', value: false }
      ],
      companies: [
        { id: 1, name: 'Progressive' },
        { id: 2, name: 'Geico' },
        { id: 3, name: 'State Farm' },
        { id: 4, name: 'Allstate' },
        { id: 5, name: 'Nationwide' },
        { id: 6, name: 'Farmers Insurance' },
        { id: 7, name: 'Liberty Mutual' },
        { id: 8, name: 'Elephant Insurance' },
        { id: 9, name: 'Mercury Insurance' },
        { id: 10, name: 'Safe Auto' },
        { id: 11, name: 'Metlife' },
        { id: 12, name: 'Infinity Insurance' },
        { id: 13, name: 'Dairyland Insurance' },
        { id: 14, name: 'The General' },
        { id: 15, name: '21st Century' },
        { id: 16, name: 'Direct General' },
        { id: 18, name: 'Esurance' },
        { id: 19, name: 'Travelers' },
        { id: 20, name: 'USAA' },
        { id: 17, name: 'Other' }
      ],
      currentlyInsured: false,
      insuranceCompanyId: 0,
      companiesToShow: 10,
      step: 10,
      currentStep: 6,
      nextStep: 7,
      partOne: true
    }
  },
  computed: {
    visibleCompanies (this: Step6) {
      return this.companies.slice(0, this.companiesToShow)
    }
  },
  methods: {
    toTop () {
      this.scrollToDivId('card_panel modal-top', 100)
    },
    getInsuranceCompanyId (val: number) {
      if (val) {
        this.$store.dispatch('setCurrentlyInsured', {
          answer: this.currentlyInsured,
          insuranceCompanyId: val,
          pBar: 7,
          currentStep: this.nextStep
        })
      }
    },
    passData (val: boolean) {
      this.currentlyInsured = val
      if (!val) {
        this.partOne = false
        this.$store.dispatch('setCurrentlyInsured', {
          answer: this.currentlyInsured,
          insuranceCompanyId: 0,
          pBar: 7,
          currentStep: this.nextStep
        })
        segmentTracking('Clicked On Currently Insured', this.currentStep)
      } else {
        this.partOne = false
        segmentTracking('Clicked On Currently Insured', this.currentStep)
      }
    }
  }
})
