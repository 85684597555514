
import { computed, defineComponent, ref } from 'vue'
import * as yup from 'yup'
import _ from 'lodash'
import { useField, useForm } from 'vee-validate'
import { Step16Data, Validation } from '@/models/interfaces/modal-steps/Step-16'
import { segmentTracking } from '@/helpers/clickEventTracking'
import CreateClientDto from '@/models/dto/CreateClientDto'
import { axiosApi } from '@/models/http/axiosApi'
import router from '@/router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Step-16',
  data (): Step16Data {
    return {
      currentStep: 16,
      nextStep: 0,
      agreementTerms: true,
      agreementCollectData: true,
      agreementText: `By clicking Submit, I provide my express consent to be contacted by ${this.siteDomain} and our Marketing Partners by telephone, which may include artificial or pre–recorded calls and/or SMS text messages, delivered via automated dialing system to the phone number that I have provided above regarding related products and services. Telephone company may impose additional charges on subscribers for messages. I understand that my consent is not required to make a purchase or obtain services and that consent can be revoked through any reasonable means. I understand that I may opt-out at any time By clicking Submit. You also agree by electronic signature to ${this.title}'s Privacy Policy and Terms of Service.`,
      isDisable: false,
      siteDomain: useStore().state.siteSettings.domain ? useStore().state.siteSettings.domain : '',
      title: useStore().state.siteSettings.title ? useStore().state.siteSettings.title : ''
    }
  },
  setup () {
    const schema = yup.object({
      phone: yup.number()
        .required()
        .typeError('Must be only digits')
        .test('len', 'Must be a valid phone number', val => val?.toString().length === 10)
    })
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: phone } = useField('phone')
    const trustedFormCertUrl = document.querySelector('input[name="TrustedFormCertUrl"]') as HTMLInputElement
    const trustedFormPingUrl = document.querySelector('input[name="TrustedFormPingUrl"]') as HTMLInputElement
    const checkFields = computed(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && phone.value ? 'cursor-pointer' : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )

    return {
      errors,
      phone,
      trustedFormCertUrl,
      trustedFormPingUrl,
      checkFields
    }
  },
  props: ['leadId'],
  methods:
      {
        passData () {
          if (_.size(this.errors) === 0 && this.phone) {
            this.isDisable = true
            this.$store.dispatch('setPhoneData', {
              phone: this.phone,
              agreementTerms: this.agreementTerms,
              agreementCollectData: this.agreementCollectData,
              leadId: this.leadId,
              trustedFormCertUrl: this.trustedFormCertUrl.value,
              trustedFormPingUrl: this.trustedFormPingUrl.value,
              agreementText: this.agreementText,
              cookie: this.$cookie.getCookie('campaign_request_id') ?? '',
              pBar: 17
            })
            const dataArr = new CreateClientDto(this.$store.state.client, this.$store.state.quote, this.$store.state.quoteDrivers)

            axiosApi.post('/client', dataArr)
              .then(response => {
                this.$cookie.setCookie('client_id', response.data.id)
                router.push({ name: 'quotes-list' })
                window.analytics.identify(response.data.id, {
                  campaignRequestId: this.$store.state.client.campaignRequestId,
                  name: `${this.$store.state.preDrivers[0].firstName} ${this.$store.state.preDrivers[0].lastName}`,
                  email: this.$store.state.client.email
                })
              })
              .finally(() => {
                this.$store.state.spinner = true
                this.$store.commit('setOpen', false)
                this.$store.commit('resetState')
              })
            segmentTracking('Clicked On Continue', this.currentStep)
          }
        }
      }
}
)
