
import { defineComponent } from 'vue'
import { Step12 } from '@/models/interfaces/modal-steps/Step-12'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-12',
  data (): Step12 {
    return {
      answers: [
        { answer: 'yes' },
        { answer: 'no' }
      ],
      drivers: this.$store.state.preDrivers,
      currentStep: 12,
      nextStep: 13,
      updatedDriversCounter: this.$store.state.preDrivers.length,
      limit: 5
    }
  },
  methods: {
    passData (val: string) {
      if (val === 'yes') {
        this.$store.commit('setProgressBar', 6)
        this.$store.commit('setStep', 6)
        segmentTracking('Clicked On Add Additional Driver', this.currentStep)
      } else {
        this.$store.dispatch('setDriver', {
          pBar: 13,
          nextStep: this.nextStep,
          driversCounter: this.updatedDriversCounter + 1,
          answer: val
        })
        segmentTracking('Clicked On Add Additional Driver', this.currentStep)
      }
    },
    deleteQuoteDrivers (index: number) {
      if (index === 0) {
        this.$store.dispatch('deleteDriver')
        this.$store.commit('setStep', 6)
      } else {
        this.updatedDriversCounter--
        this.drivers.splice(index, 1)
      }
    }
  }
})
