
import { defineComponent } from 'vue'
import _ from 'lodash'
import StatesMap from '@/map/states.map'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Header',
  props: ['stateId', 'text'],
  data () {
    return {
      cloneText: this.text,
      logo: useStore().state.siteSettings.logo ? useStore().state.siteSettings.logo.url : ''
    }
  },
  watch: {
    stateId (id: string) {
      id && this.changeText(id)
    }
  },
  methods: {
    getStateName (id: string) {
      return _.invert(_.fromPairs(StatesMap))[id]
    },
    changeText (id: string) {
      this.cloneText = this.text.replace('state', this.getStateName(id))
    }
  }
})
