
import { defineComponent } from 'vue'
import { Step7 } from '@/models/interfaces/modal-steps/Step-7'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-7',
  data (): Step7 {
    return {
      answers: [
        { answer: 'male' },
        { answer: 'female' }
      ],
      currentStep: 7,
      nextStep: 8
    }
  },
  methods: {
    passData (val: string) {
      this.$store.dispatch('setGender', {
        currentStep: this.nextStep,
        pBar: 8,
        gender: val
      })
      segmentTracking('Clicked On Gender', this.currentStep)
    }
  }
})
