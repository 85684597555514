import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "z-50 header fixed lg:sticky lg:mb-0 w-full top-0 bg-white flex items-center justify-between" }
const _hoisted_2 = { class: "container mx-auto flex flex-row justify-between font-montserrat uppercase" }
const _hoisted_3 = { class: "bg-white border-gray-200 py-2.5 rounded dark:bg-gray-800 w-full" }
const _hoisted_4 = { class: "flex flex-wrap justify-start items-center w-full" }
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex justify-between lg:ml-10 mt-4 lg:mt-0" }
const _hoisted_8 = { class: "z-allOver capitalize text-sm lg:text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "flex max-w-logo"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.logo,
                  alt: "Logo",
                  class: "max-h-16"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.cloneText), 1)
          ])
        ])
      ])
    ])
  ]))
}