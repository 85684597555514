export default {
  methods: {
    scrollToDivId (id: string, time = 0): void {
      setTimeout(function () {
          // eslint-disable-next-line no-unused-expressions
          document.getElementById(id)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          })
      }, time
      )
    }
  }
}
