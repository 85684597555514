
import { defineComponent } from 'vue'
import Footer from '@/views/layouts/parts/Footer.vue'
import Header from '@/views/layouts/parts/Header.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    Footer,
    Header
  }
})
