
import { computed, defineComponent, ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import _ from 'lodash'
import * as yup from 'yup'
import { Step11Data, Validation } from '@/models/interfaces/modal-steps/Step-11'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-11',
  data (): Step11Data {
    return {
      currentStep: 11,
      nextStep: 12
    }
  },
  setup () {
    const schema = yup.object({
      name: yup.string()
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .min(2),
      lastName: yup.string()
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .min(2)
    })
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: name } = useField('name')
    const { value: lastName } = useField('lastName')

    const checkFields = computed(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && name.value && lastName.value ? 'cursor-pointer' : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )

    return {
      errors,
      name,
      lastName,
      checkFields
    }
  },
  methods: {
    passData () {
      if (_.size(this.errors) === 0 && this.name && this.lastName) {
        this.$store.dispatch('setFullName', {
          firstName: this.name,
          lastName: this.lastName,
          currentStep: this.nextStep,
          pBar: 12
        })
        segmentTracking('Clicked On Continue', this.currentStep)
      }
    }
  }
})
