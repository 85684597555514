
import { defineComponent } from 'vue'
import { Step4 } from '@/models/interfaces/modal-steps/Step-4'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-4',
  data (): Step4 {
    return {
      answers: [
        { answer: 'yes', value: true },
        { answer: 'no', value: false }
      ],
      currentStep: 4,
      nextStep: 5
    }
  },
  methods: {
    passData (val: boolean) {
      this.$store.dispatch('setVOwnershipData', {
        currentStep: this.nextStep,
        pBar: 5,
        ownership: val
      })
      segmentTracking('Clicked On Vehicle Ownership', this.currentStep)
    }
  }
})
