import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { VueCookieNext } from 'vue-cookie-next'
import Home from '../views/pages/Home.vue'
import { axiosApi } from '@/models/http/axiosApi'
import store from '../store/index'
import { handleVisitorCookie } from '@/helpers/handleVisitorCookie'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: (to, from, next) => {
      !VueCookieNext.getCookie('client_id') ? next() : next({ name: 'welcome-back' })
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/About.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/pages/Contact.vue')
  },
  {
    path: '/ccpa',
    name: 'ccpa',
    component: () => import(/* webpackChunkName: "contact" */ '../views/pages/CCPA.vue')
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "contact" */ '../views/pages/Unsubscribe.vue')
  },
  {
    path: '/join-us',
    name: 'join-us',
    component: () => import(/* webpackChunkName: "join-us" */ '../views/pages/JoinUs.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/pages/Terms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/pages/PrivacyPolicy.vue')
  },
  {
    path: '/marketing-partners',
    name: 'marketing-partners',
    component: () => import(/* webpackChunkName: "marketing-partners" */ '../views/pages/MarketingPartners.vue')
  },
  {
    path: '/quotes-list',
    name: 'quotes-list',
    component: () => import(/* webpackChunkName: "thank-you" */ '../views/pages/QuotesList.vue'),
    beforeEnter: (to, from, next) => {
      !VueCookieNext.getCookie('client_id') ? next({ name: 'home' }) : next()
    }
  },
  {
    path: '/welcome-back',
    name: 'welcome-back',
    component: () => import(/* webpackChunkName: "welcome-back" */ '../views/pages/WelcomeBack.vue'),
    beforeEnter: (to, from, next) => {
      !VueCookieNext.getCookie('client_id') ? next({ name: 'home' }) : next()
    }
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import(/* webpackChunkName: "welcome-back" */ '../views/pages/Campaign.vue')
  }
]

const router = createRouter({
  scrollBehavior () {
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  axiosApi.get('site-setting').then((response) => {
    if (localStorage.getItem('site_setting_version') !== response.headers['site-setting-version']) {
      localStorage.setItem('site_setting_version', response.headers['site-setting-version'])
      localStorage.setItem('site_settings', JSON.stringify(response.data))
      store.dispatch('setSiteData', response.data)
    } else {
      store.dispatch('setSiteData', JSON.parse(localStorage.getItem('site_settings') || ''))
    }
    next()
  })
})
router.beforeEach((to, from, next) => {
  if (to.name === 'campaign' || VueCookieNext.isCookieAvailable('campaign_request_id')) {
    next()
  } else {
    axiosApi.post('campaign-request/generate').then((response) => {
      VueCookieNext.setCookie('campaign_request_id', response.data.id)
      handleVisitorCookie(response.data.id)
    }).finally(() => {
      next()
    })
  }
})

export default router
