
import { defineComponent } from 'vue'
import { Step8 } from '@/models/interfaces/modal-steps/Step-8'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-8',
  data (): Step8 {
    return {
      answers: [
        { answer: 'yes', value: true },
        { answer: 'no', value: false }
      ],
      currentStep: 8,
      nextStep: 9
    }
  },
  methods:
    {
      passData (val: boolean) {
        this.$store.dispatch('setMarriage', {
          currentStep: this.nextStep,
          pBar: 9,
          married: val
        })
        segmentTracking('Clicked On Marital Status', this.currentStep)
      }
    }
})
