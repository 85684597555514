
import { defineComponent } from 'vue'
import { Step10, Event } from '@/models/interfaces/modal-steps/Step-10'
import Accidents from '@/views/components/modal-steps/step10/Step-10-accidents.vue'
import Tickets from '@/views/components/modal-steps/step10/Step-10-tickets.vue'
import DUIs from '@/views/components/modal-steps/step10/Step-10-duis.vue'
import { segmentTracking } from '@/helpers/clickEventTracking'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'

export default defineComponent({
  name: 'Step-10',
  mixins: [ScrollToMixin],
  data (): Step10 {
    return {
      steps: {
        step1: true,
        step2: false,
        step3: false,
        step4: false
      },
      accident: false,
      ticket: false,
      dui: false,
      accidents: [],
      tickets: [],
      duis: [],
      questions: [
        { answer: 'yes', value: true },
        { answer: 'no', value: false }
      ],
      currentStep: 10,
      nextStep: 11
    }
  },
  methods: {
    toTop () {
      this.scrollToDivId('card_panel modal-top', 100)
    },
    nextStepData (data: Event) {
      if (data.step === 'accident') {
        this.accident = data.toggle
        this.accidents = data.accidents
      } else if (data.step === 'tickets') {
        this.ticket = data.toggle
        this.tickets = data.tickets
      } else if (data.step === 'duis') {
        this.dui = data.toggle
        this.duis = data.duis
        this.steps.step4 = data.toggle
      }
      this.switchSteps()
    },
    passData () {
      if (this.accident || this.ticket || this.dui) {
        this.switchSteps()
      } else {
        this.$store.dispatch('getDInfo', {
          accidents: this.accidents,
          tickets: this.tickets,
          duis: this.duis,
          currentStep: this.nextStep,
          pBar: 11
        })
      }
      segmentTracking('Clicked On Continue')
    },
    setStepStatus (status: boolean): void {
      for (const statusKey in this.steps) {
        this.steps[statusKey] = status
      }
    },
    switchSteps () {
      this.setStepStatus(false)
      this.toTop()
      if (this.accident) {
        this.steps.step2 = true
      } else if (this.ticket) {
        this.steps.step3 = true
      } else if (this.dui) {
        this.steps.step4 = true
      } else {
        this.$store.dispatch('getDInfo', {
          accidents: this.accidents,
          tickets: this.tickets,
          duis: this.duis,
          currentStep: this.nextStep,
          pBar: 11
        })
      }
    }
  },
  components: {
    Accidents,
    Tickets,
    DUIs
  }
})
