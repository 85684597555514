
import { computed, defineComponent, onMounted, ref } from 'vue'
import { Step1Data, Validation } from '@/models/interfaces/modal-steps/Zipcode'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import _ from 'lodash'
import { useCookie } from 'vue-cookie-next'
import { axiosApi } from '@/models/http/axiosApi'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-1',
  data (): Step1Data {
    return {
      maxLimit: 5,
      currentStep: 0,
      nextStep: 1
    }
  },
  setup () {
    const cookie = useCookie()

    onMounted(() => {
      const campaignRequestId = 'campaign_request_id'
      if (cookie.isCookieAvailable(campaignRequestId) && cookie.getCookie(campaignRequestId) !== null) {
        axiosApi.get(`/campaign-request/${cookie.getCookie(campaignRequestId)}`).then((response) => {
          if (response.data.zipcode) {
            zipcode.value = response.data.zipcode
          }
        })
      }
    })

    const schema = yup.object({
      zipcode: yup.string()
        .required()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits')
    })

    const { errors } = useForm<Validation>({
      validationSchema: schema
    })

    const { value: zipcode } = useField('zipcode')

    const checkFields = computed(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && zipcode.value ? 'bg-blue-btn600 cursor-pointer' : 'bg-blue-btnLight cursor-not-allowed'

        return `${classes.value}`
      }
    )

    return {
      errors,
      zipcode,
      checkFields,
      segmentTracking
    }
  },
  methods: {
    firstStep () {
      const zipcode = this.zipcode as string
      axiosApi.get(`/zip-code/${this.zipcode}`)
        .then((response) => {
          this.$store.dispatch('setZipCode', {
            currentStep: this.currentStep + 1,
            pBar: 1,
            state: response.data ? response.data.stateId : '',
            city: response.data ? response.data.city : '',
            zipcode: zipcode
          })
        })
    },
    passData () {
      if ((_.size(this.errors)) === 0 && this.zipcode) {
        this.segmentTracking('Clicked Button GO', this.currentStep)
        this.firstStep()
      }
    }
  }
})
