import Driver from '@/models/interfaces/Driver'
import { Duis } from '@/models/interfaces/modal-steps/step10/Step-10-duis'
import { Tickets } from '@/models/interfaces/modal-steps/step10/Step-10-tickets'
import { Accidents } from '@/models/interfaces/modal-steps/step10/Step-10-accident'

export default class DriverDto {
  public readonly married: boolean
  public readonly accidents: Array<Accidents>
  public readonly tickets: Array<Tickets>
  public readonly duis: Array<Duis>
  public readonly ownership: boolean
  public readonly currentlyInsured: boolean
  public readonly insuranceCompanyId: number
  public readonly gender: string
  public readonly birthday: string
  public readonly firstName: string
  public readonly lastName: string

  constructor (data: Driver) {
    this.currentlyInsured = data.currentlyInsured ? Boolean(data.currentlyInsured) : false
    this.married = data.married ? Boolean(data.married) : false
    this.accidents = data.accidents ? data.accidents : []
    this.tickets = data.tickets ? data.tickets : []
    this.duis = data.duis ? data.duis : []
    this.ownership = data.ownership ? Boolean(data.ownership) : false
    this.insuranceCompanyId = data.insuranceCompanyId ? Number(data.insuranceCompanyId) : 0
    this.gender = data.gender ? String(data.gender) : ''
    this.birthday = data.birthday ? String(data.birthday) : ''
    this.firstName = data.firstName ? String(data.firstName) : ''
    this.lastName = data.lastName ? String(data.lastName) : ''
  }
}
