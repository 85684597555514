
import { defineComponent } from 'vue'
import { Step1 } from '@/models/interfaces/modal-steps/Step-1'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Step-1',
  data (): Step1 {
    return {
      years: [],
      yearsToShow: 20,
      step: 20,
      currentStep: 1,
      nextStep: 2
    }
  },
  mounted () {
    this.years = this.getYears()
  },
  methods: {
    getYears () {
      const years = []
      for (let i = new Date().getFullYear(); i >= Number(process.env.VUE_APP_CAR_START_YEAR); i--) {
        years.push({ year: i })
      }
      return years
    },
    passData (val: number) {
      this.$store.dispatch('setVYearData', {
        currentStep: this.nextStep,
        pBar: 2,
        year: val
      })
      segmentTracking('Clicked On Year', this.currentStep)
    }
  },
  computed: {
    visibleYears (this: Step1) {
      return this.years.slice(0, this.yearsToShow)
    }
  }
})
