import axios, { AxiosResponse } from 'axios'

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

export {
  axiosApi,
  AxiosResponse
}
